import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, Row, Col, Spin } from "antd";
import moment from "moment-timezone";
import { useRest } from "../../services/http";
import { RiClipboardLine } from "react-icons/ri";

const UpcomingBirthdays = () => {
  const { get, loading, data, status } = useRest();
  const [tablePagination, setTablePagination] = useState({
    current: 1,
    pageSize: 15,
  });

  useEffect(() => {
    if (data) {
      setTablePagination({
        ...tablePagination,
        pageSize: data?.per_page || 15,
        total: data?.total || 0,
      });
    }
  }, [data]);

  useEffect(() => {
    get("upcomingBirthdays", {
      page: tablePagination.current,
    }).then((response) => {
      console.log("API Response:", response);
    });
  }, [tablePagination?.current]);

  useEffect(() => {
    console.log("upcomingBirthdays Status:", status);
  }, [status]);

  return (
    <Spin spinning={loading}>
      <Row gutter={[16, 16]}>
        {data?.map((item) => (
          <Col xs={24} sm={12} md={8} lg={8} key={item.id}>
            <Card>
              <p
                style={{
                  fontSize: 18,
                }}
              >
                {" "}
                <Link to={`/view-client/${item.id}`}>{item?.user?.name}</Link>
              </p>
              <hr />
              <p>{moment(item?.birthdate).format("DD/MM/YYYY")}</p>
              <p>
                <span
                  style={{ textDecoration: "none", cursor: "pointer" }}
                  onClick={() => {
                    navigator.clipboard.writeText(item?.user?.email);
                  }}
                >
                  <RiClipboardLine className="remix-icon" /> {item?.user?.email}
                </span>
              </p>
              <p>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigator.clipboard.writeText(item?.phone_number);
                  }}
                >
                  <RiClipboardLine className="remix-icon" />{" "}
                  {item?.phone_number}
                </span>
              </p>
            </Card>
          </Col>
        ))}
      </Row>
    </Spin>
  );
};

export default UpcomingBirthdays;
