import { Button, Card, Col, Row, Table } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { RiArrowRightLine } from "react-icons/ri";
import { StatusType } from "../listFlow/type";
import UpcomingBirthdays from "../../components/upcomingBirthdays";
import Wrapper from "../../components/layout";
import moment from "moment-timezone";
import styled from "./styled.module.scss";
import { useRest } from "../../services/http";
import ReactApexChart from "react-apexcharts";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();
  const {
    get: dashboardCards,
    data: dashboardCardsData,
    status: dashboardCardsStatus,
  } = useRest();

  const {
    get: dashboardFlows,
    loading: dashboardFlowsLoading,
    data: dashboardFlowsData,
  } = useRest();

  const [tablePaginationFlows, setTablePaginationFlows] = useState({
    current: 1,
    pageSize: 10,
  });

  const {
    get: dashboardLastInteractions,
    loading: dashboardLastInteractionsLoading,
    data: dashboardLastInteractionsData,
  } = useRest();

  const [tablePaginationLastInteractions, setTablePaginationLastInteractions] =
    useState({
      current: 1,
      pageSize: 6,
    });

  useEffect(() => {
    dashboardFlows("dashboardFlows", {
      page: tablePaginationFlows.current,
      per_page: tablePaginationFlows.pageSize,
    });
  }, [tablePaginationFlows?.current, tablePaginationFlows?.pageSize]);

  useEffect(() => {
    if (dashboardFlowsData?.total) {
      setTablePaginationFlows((prev) => ({
        ...prev,
        total: dashboardFlowsData.total,
      }));
    }
  }, [dashboardFlowsData?.total]);

  useEffect(() => {
    dashboardCards("dashboardCards");
  }, []);

  useEffect(() => {
    dashboardLastInteractions("dashboardInteractions", {
      page: tablePaginationLastInteractions.current,
      per_page: tablePaginationLastInteractions.pageSize,
    });
  }, [
    tablePaginationLastInteractions?.current,
    tablePaginationLastInteractions?.pageSize,
  ]);

  useEffect(() => {
    if (dashboardLastInteractionsData?.total) {
      setTablePaginationLastInteractions((prev) => ({
        ...prev,
        total:
          dashboardLastInteractionsData?.total &&
          dashboardLastInteractionsData?.total > 12
            ? 12
            : dashboardLastInteractionsData?.total,
      }));
    }
  }, [dashboardLastInteractionsData?.total]);

  const columns = [
    {
      title: "Cliente",
      dataIndex: "customer",
      key: "customer",
      sorter: (a, b) => a.customer.localeCompare(b.customer),
      sortDirections: ["descend"],
    },
    {
      title: "Serviço",
      dataIndex: "service_title",
      key: "service_title",
      sorter: (a, b) => a.service_title.localeCompare(b.service_title),
      sortDirections: ["descend"],
    },
    {
      title: "Etapa",
      dataIndex: "step_title",
      key: "step_title",
      sorter: (a, b) => a.step_title.localeCompare(b.step_title),
      sortDirections: ["descend"],
    },
    {
      title: "Prazo",
      dataIndex: "deadline_date",
      key: "deadline_date",
      sorter: (a, b) =>
        String(a.deadline_date)?.localeCompare(String(b.deadline_date)),
      sortDirections: ["descend"],
      render: (record) =>
        record
          ? moment(record).tz("America/Sao_Paulo").format("DD/MM/YYYY")
          : "-",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (record) => StatusType[record],
    },
    {
      title: "Ação",
      key: "action",
      dataIndex: "",
      render: (_, record) => (
        <Link to={"/interaction/" + record?.flow_id}>
          <Button
            icon={<RiArrowRightLine className="remix-icon" />}
            onClick={() => handleInteractionClick(record)}
          ></Button>
        </Link>
      ),
    },
  ];

  const columnsLastInteractions = [
    {
      title: "Cliente",
      dataIndex: "customer",
      key: "customer",
      sorter: (a, b) => a.customer.localeCompare(b.customer),
      sortDirections: ["descend"],
    },
    {
      title: "Serviço",
      dataIndex: "service_title",
      key: "service_title",
      sorter: (a, b) => a.service_title.localeCompare(b.service_title),
      sortDirections: ["descend"],
    },
    {
      title: "Etapa",
      dataIndex: "step_title",
      key: "step_title",
      sorter: (a, b) => a.step_title.localeCompare(b.step_title),
      sortDirections: ["descend"],
    },
    {
      title: "Responsável",
      dataIndex: "assignee",
      key: "assignee",
      sorter: (a, b) => a.assignee.localeCompare(b.assignee),
      sortDirections: ["descend"],
    },
    {
      title: "Prazo",
      dataIndex: "deadline_date",
      key: "deadline_date",
      sorter: (a, b) =>
        String(a.deadline_date)?.localeCompare(String(b.deadline_date)),
      sortDirections: ["descend"],
      render: (record) =>
        record
          ? moment(record).tz("America/Sao_Paulo").format("DD/MM/YYYY")
          : "-",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (record) => StatusType[record],
    },
    {
      title: "Ação",
      key: "action",
      dataIndex: "",
      render: (_, record) => (
        <Link to={"/interaction/" + record?.flow_id}>
          <Button
            icon={<RiArrowRightLine className="remix-icon" />}
            onClick={() => handleInteractionClick(record)}
          ></Button>
        </Link>
      ),
    },
  ];

  const columnsClientsServices = [
    {
      title: "Serviço",
      dataIndex: "service",
      key: "service",
      sorter: (a, b) => a.service.localeCompare(b.service),
    },
    {
      title: "Clientes",
      dataIndex: "clients",
      key: "clients",
      sorter: (a, b) => a.clients - b.clients,
    },
    {
      title: "Não Iniciado",
      dataIndex: "not_services",
      key: "not_services",
      sorter: (a, b) => a.not_services - b.not_services,
    },
    {
      title: "Em Planejamento",
      dataIndex: "emp_services",
      key: "emp_services",
      sorter: (a, b) => a.emp_services - b.emp_services,
    },
    {
      title: "Em Andamento",
      dataIndex: "inp_services",
      key: "inp_services",
      sorter: (a, b) => a.inp_services - b.inp_services,
    },
    {
      title: "Em Validação",
      dataIndex: "env_services",
      key: "env_services",
      sorter: (a, b) => a.env_services - b.env_services,
    },
    {
      title: "Pendente",
      dataIndex: "pen_services",
      key: "pen_services",
      sorter: (a, b) => a.pen_services - b.pen_services,
    },
    {
      title: "Finalizado",
      dataIndex: "fin_services",
      key: "fin_services",
      sorter: (a, b) => a.fin_services - b.fin_services,
    },
    {
      title: "Fora do Prazo",
      dataIndex: "out_of_date_services",
      key: "out_of_date_services",
      sorter: (a, b) => a.out_of_date_services - b.out_of_date_services,
    },
  ];

  const calculateTotals = (data) => {
    if (!data || data.length === 0) return [];

    const totals = data.reduce(
      (acc, item) => {
        acc.clients += Number(item.clients);
        acc.flows += Number(item.flows);
        acc.not_services += Number(item.not_services);
        acc.emp_services += Number(item.emp_services);
        acc.inp_services += Number(item.inp_services);
        acc.env_services += Number(item.env_services);
        acc.pen_services += Number(item.pen_services);
        acc.fin_services += Number(item.fin_services);
        acc.out_of_date_services += Number(item.out_of_date_services);
        return acc;
      },
      {
        service: "Totais",
        clients: 0,
        flows: 0,
        not_services: 0,
        emp_services: 0,
        inp_services: 0,
        env_services: 0,
        pen_services: 0,
        fin_services: 0,
        out_of_date_services: 0,
      }
    );

    return [...data, totals];
  };

  const enhancedDataSource = calculateTotals(dashboardCardsData?.services);

  const chartData = {
    series: [
      {
        name: "",
        data: dashboardCardsData?.steps.map((item) => item.steps),
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: dashboardCardsData?.steps.map((item) =>
          item.responsible.toUpperCase()
        ),
      },
      yaxis: {
        title: {
          text: "",
        },
      },
    },
  };

  const handleInteractionClick = (record) => {
    navigate("/interaction/" + record?.flow_id);
  };

  const handleTableChangeFlow = (pagination) => {
    setTablePaginationFlows((prev) => ({
      ...prev,
      current: pagination.current,
      pageSize: pagination.pageSize,
    }));
  };

  const handleTableChangeLastInteractions = (pagination) => {
    setTablePaginationLastInteractions((prev) => ({
      ...prev,
      current: pagination.current,
      pageSize: pagination.pageSize,
    }));
  };

  return (
    <Wrapper>
      {dashboardCardsStatus.success && (
        <>
          <h3>Resumo Geral</h3>
          <Row gutter={[8, 8]}>
            <Card className={[styled.card, styled["bg-blue"]]}>
              <h4 className={styled["card-title"]}>CLIENTES</h4>
              <span className={styled.statistic}>
                {dashboardCardsData?.cards?.clients}
              </span>
            </Card>

            <Card className={[styled.card, styled["bg-blue"]]}>
              <h4 className={styled["card-title"]}>PROSPECTS</h4>
              <span className={styled.statistic}>
                {dashboardCardsData?.cards?.prospects}
              </span>
            </Card>

            <Card className={[styled.card, styled["bg-blue"]]}>
              <h4 className={styled["card-title"]}>PROCESSOS</h4>
              <span className={styled.statistic}>
                {dashboardCardsData?.cards?.flows}
              </span>
            </Card>

            <Card className={[styled.card, styled["bg-red"]]}>
              <h4 className={styled["card-title"]}>PROCESSOS FORA DO PRAZO</h4>
              <span className={styled.statistic}>
                {dashboardCardsData?.cards?.out_of_date_services}
              </span>
            </Card>

            <Card
              className={[styled.card, styled["bg-red-with-hover"]]}
              onClick={() => {
                navigate("/unread-interactions-report");
              }}
            >
              <h4 className={styled["card-title"]}>INTERAÇÕES NÃO LIDAS</h4>
              <span className={styled.statistic}>
                {dashboardCardsData?.cards?.unread_interactions}
              </span>
            </Card>
          </Row>
        </>
      )}

      {dashboardCardsStatus.success && (
        <>
          <h3>Resumo de Prospects</h3>
          <Row gutter={[8, 8]}>
            <Card className={[styled.card, styled["bg-blue"]]}>
              <h4 className={styled["card-title"]}>NOVOS</h4>
              <span className={styled.statistic}>
                {dashboardCardsData?.cards?.new_prospects}
              </span>
            </Card>

            <Card className={[styled.card, styled["bg-blue"]]}>
              <h4 className={styled["card-title"]}>EM NEGOCIAÇÃO</h4>
              <span className={styled.statistic}>
                {dashboardCardsData?.cards?.neg_prospects}
              </span>
            </Card>

            <Card className={[styled.card, styled["bg-blue"]]}>
              <h4 className={styled["card-title"]}>AGUARDANDO</h4>
              <span className={styled.statistic}>
                {dashboardCardsData?.cards?.wai_prospects}
              </span>
            </Card>

            <Card className={[styled.card, styled["bg-blue"]]}>
              <h4 className={styled["card-title"]}>ENCERRADO</h4>
              <span className={styled.statistic}>
                {dashboardCardsData?.cards?.clo_prospects}
              </span>
            </Card>
          </Row>
        </>
      )}

      {dashboardCardsStatus.success && (
        <>
          <h3>Resumo de Etapas x Clientes</h3>
          <Row gutter={[8, 8]}>
            <Card className={[styled.card, styled["bg-blue"]]}>
              <h4 className={styled["card-title"]}>TOTAL DE ETAPAS</h4>
              <span className={styled.statistic}>
                {enhancedDataSource[enhancedDataSource.length - 1]?.flows}
              </span>
            </Card>

            <Card className={[styled.card, styled["bg-blue"]]}>
              <h4 className={styled["card-title"]}>NÃO INICIADO</h4>
              <span className={styled.statistic}>
                {
                  enhancedDataSource[enhancedDataSource.length - 1]
                    ?.not_services
                }
              </span>
            </Card>

            <Card className={[styled.card, styled["bg-blue"]]}>
              <h4 className={styled["card-title"]}>EM PLANEJAMENTO</h4>
              <span className={styled.statistic}>
                {
                  enhancedDataSource[enhancedDataSource.length - 1]
                    ?.emp_services
                }
              </span>
            </Card>

            <Card className={[styled.card, styled["bg-blue"]]}>
              <h4 className={styled["card-title"]}>EM ANDAMENTO</h4>
              <span className={styled.statistic}>
                {
                  enhancedDataSource[enhancedDataSource.length - 1]
                    ?.inp_services
                }
              </span>
            </Card>

            <Card className={[styled.card, styled["bg-blue"]]}>
              <h4 className={styled["card-title"]}>EM VALIDAÇÃO</h4>
              <span className={styled.statistic}>
                {
                  enhancedDataSource[enhancedDataSource.length - 1]
                    ?.env_services
                }
              </span>
            </Card>

            <Card className={[styled.card, styled["bg-red"]]}>
              <h4 className={styled["card-title"]}>PENDENTE</h4>
              <span className={styled.statistic}>
                {
                  enhancedDataSource[enhancedDataSource.length - 1]
                    ?.pen_services
                }
              </span>
            </Card>

            <Card className={[styled.card, styled["bg-blue"]]}>
              <h4 className={styled["card-title"]}>FINALIZADO</h4>
              <span className={styled.statistic}>
                {
                  enhancedDataSource[enhancedDataSource.length - 1]
                    ?.fin_services
                }
              </span>
            </Card>
          </Row>

          <Card className={styled.card}>
            <Table
              columns={columnsClientsServices}
              dataSource={dashboardCardsData?.services}
              rowKey={(record) => record?.key}
              pagination={false}
              scroll={{ x: 800 }}
              size="small"
              summary={() => (
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <strong>Totais</strong>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    {enhancedDataSource[enhancedDataSource.length - 1]?.clients}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    {
                      enhancedDataSource[enhancedDataSource.length - 1]
                        ?.not_services
                    }
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>
                    {
                      enhancedDataSource[enhancedDataSource.length - 1]
                        ?.emp_services
                    }
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>
                    {
                      enhancedDataSource[enhancedDataSource.length - 1]
                        ?.inp_services
                    }
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5}>
                    {
                      enhancedDataSource[enhancedDataSource.length - 1]
                        ?.env_services
                    }
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6}>
                    {
                      enhancedDataSource[enhancedDataSource.length - 1]
                        ?.pen_services
                    }
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7}>
                    {
                      enhancedDataSource[enhancedDataSource.length - 1]
                        ?.fin_services
                    }
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={8}>
                    {
                      enhancedDataSource[enhancedDataSource.length - 1]
                        ?.out_of_date_services
                    }
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              )}
            />
          </Card>
        </>
      )}

      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Card className={styled.card}>
            <h4>Resumo de Responsáveis x Etapa</h4>
            <ReactApexChart
              options={chartData.options}
              series={chartData.series}
              type="bar"
              height={350}
            />
          </Card>
        </Col>
      </Row>

      <Card className={styled.card}>
        <h4 className="hp-mb-18">Suas Etapas</h4>
        <Table
          size="small"
          columns={columns}
          rowKey={(record) => record?.id}
          dataSource={dashboardFlowsData?.data}
          pagination={tablePaginationFlows}
          loading={dashboardFlowsLoading}
          onChange={handleTableChangeFlow}
          scroll={{ x: 800 }}
        />
      </Card>

      <Card className={styled.card}>
        <h4 className="hp-mb-18">Últimas Interações</h4>
        <Table
          columns={columnsLastInteractions}
          rowKey={(_, index) => index}
          dataSource={dashboardLastInteractionsData?.data}
          pagination={tablePaginationLastInteractions}
          loading={dashboardLastInteractionsLoading}
          onChange={handleTableChangeLastInteractions}
          scroll={{ x: 800 }}
          size="small"
        />
      </Card>

      <Card className={styled.card}>
        <h4 className="hp-mb-18">Aniversariantes da Semana</h4>
        <UpcomingBirthdays />
      </Card>
    </Wrapper>
  );
};

export default Dashboard;
